<template>
  <div id="programs-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-row wrap>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Title</h5>
              <p class="preview-content">{{ recordData.EventTitle }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Description</h5>
              <p class="preview-content">{{ recordData.EventDescription }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Type</h5>
              <p class="preview-content">{{ recordData.OrganizationTypeName }} Program</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3" v-if="recordData.ZoneName != '' && recordData.ZoneName != null">
              <h5 class="blue--text text--lighten">Zone Name</h5>
              <p class="preview-content">{{ recordData.ZoneName }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3" v-if="recordData.LomName != '' && recordData.LomName != null">
              <h5 class="blue--text text--lighten">LO Name</h5>
              <p class="preview-content">{{ recordData.LomName }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program date</h5>
              <p class="preview-content">{{ recordData.EventDateTxt }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Time</h5>
              <p class="preview-content">{{ recordData.StartTime }} - {{ recordData.EndTime }}</p>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Image</h5>
              <viewer rebuild :images="recordData.EventImagePath">
                <img
                  style="padding: 5px"
                  width="100"
                  height="100"
                  :src="recordData.EventImagePath"
                />
              </viewer>
            </v-col>
            <v-col cols="12" sm="12" lg="3" md="3">
              <h5 class="blue--text text--lighten">Program Content</h5>
              <p class="preview-content">{{ recordData.EventContent }}</p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="4" lg="4" md="4">
              <h5 class="blue--text text--lighten">Contact Persion details</h5>
              <p class="preview-content">{{ recordData.ContactPersonDetails }}</p>
            </v-col>
            <v-col cols="12" sm="4" lg="4" md="4">
              <h5 class="blue--text text--lighten">Contact Persion Email</h5>
              <p class="preview-content">{{ recordData.ContactPersonEmailId }}</p>
            </v-col>
            <v-col cols="12" sm="4" lg="4" md="4">
              <h5 class="blue--text text--lighten">Contact Persion Phone No</h5>
              <p class="preview-content">{{ recordData.ContactPersonMobileNo }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    recordData: {
      required: true
    },
    pageTitle: {
      type: String
    }
  },
  data() {
    return {
      valid1: false,
      valid2: false,
      ProgressLoadingFlag: false,
      submitFlag: false,
      LoadingFlag: false,

      row: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
    }
  },
  beforeMount() {
    // this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#national-Programs-preview {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>