<template>
  <div id="programs-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ PageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="searchForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4" v-if="EventTypeFlag">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Program Type
                  </h6>
                </label>
                <v-select
                  :reduce="(option) => option.value"
                  v-model="EventType"
                  :rules="EventTypeRules"
                  :items="EventTypeOptions"
                  :loading="EventTypeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4" v-if="EventType == 2 || EventType == 3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Zone
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4" v-if="EventType == 3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> LOM
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="LomCode"
                  :rules="LomCodeRules"
                  :items="LomCodeOptions"
                  :loading="LomCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Program Title
                  </h6>
                </label>
                <v-text-field
                  v-model="EventTitle"
                  :rules="EventTitleRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4">
                <label>
                  <h6><span class="text-danger">*</span> Organization Type</h6>
                </label>

                <v-select
                  :reduce="(option) => option.value"
                  :items="OrganizationTypeOptions"
                  :rules="OrganizationTypeRules"
                  v-model="OrganizationType"
                  dense
                  clearable
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Program Description
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="EventDescription"
                  :rules="EventDescriptionRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    <span class="text-danger">*</span> From Date
                  </h6>
                </label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      :rules="FromDateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="FromDate" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    To Date
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="ToDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Start Time
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      v-model="StartTime"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="StartTime"
                    full-width
                    @click:minute="$refs.menu3.save(StartTime)"
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    End Time
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="EndTime"
                      required
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu4"
                    v-model="EndTime"
                    full-width
                    @click:minute="$refs.menu4.save(EndTime)"
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="12" lg="12" md="12">
                <label>
                  <h6>
                    Program Content
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-textarea
                  v-model="EventContent"
                  value
                  :maxlength="500"
                  :counter="500"
                  outlined
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Program Location
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field v-model="EventLocation" :rules="EventLocationRules" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Google Map Link
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field v-model="GoogleMabLink" :rules="GoogleMabLinkRules" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Website Link
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field v-model="WebsiteLink" :rules="WebsiteLinkRules" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Contact Person Details
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-textarea
                  v-model="ContactPersonDetails"
                  value
                  :maxlength="100"
                  :counter="100"
                  outlined
                  solo
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Contact Person Email Id
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="ContactPersonEmailId"
                  :rules="ContactPersonEmailIdRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>
                    Contact Person Mobile No
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-text-field
                  v-model="ContactPersonMobileNo"
                  :rules="ContactPersonMobileNoRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6 class="mycard-text1 fsize-3">
                  Program Image
                  <small>(Optional)</small>
                </h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Upload member image"
                  prepend-icon="mdi-card-bulleted-settings"
                  append-outer-icon="mdi-delete"
                  v-model="EventImage"
                  @change="addImage(1)"
                  @click:append-outer="deleteImage(1)"
                ></v-file-input>
                <br />
                <div class="actions text-center" v-if="SelectedImage.ImageUrl != null">
                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn fab dark small color="indigo" @click.prevent="flip(true, false)">
                      <v-icon>fa fa-caret-right</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn fab dark small color="indigo" @click.prevent="flip(false, true)">
                      <v-icon>fa fa-caret-up</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn fab dark small color="indigo" @click.prevent="rotate(90)">
                      <v-icon>fa fa-redo</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn fab dark small color="indigo" @click.prevent="rotate(-90)">
                      <v-icon>fa fa-undo</v-icon>
                    </v-btn>
                  </b-button-group>
                </div>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6 class="mycard-text1 fsize-3">Selected Program Image</h6>
                <cropper
                  ref="cropper"
                  :src="SelectedImage.ImageUrl"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 2,
                    previewClass: 'preview',
                  }"
                  :stencil-size="{
                    width: 600,
                    height: 300,
                  }"
                  image-restriction="stencil"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6 class="mycard-text1 fsize-3">Featured Program</h6>
                <v-switch v-model="Featured" color="green"></v-switch>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6 class="mycard-text1 fsize-3">Program Status</h6>
                <v-switch v-model="EventStatus" color="green"></v-switch>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="getCroppedImage()"
                  :loading="submitFlag"
                  color="success"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Submit</v-btn>
                <v-btn
                  @click="closePrompt"
                  color="primary"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid1: false,
      ProgressLoadingFlag: false,
      submitFlag: false,
      LoadingFlag: false,

      EventTitle: "",
      EventTitleRules: [v => !!v || "Program Title is required"],
      EventDescription: "",
      EventDescriptionRules: [],
      EventContent: "",
      EventContentRules: [],
      EventLocation: "",
      EventLocationRules: [],
      GoogleMabLink: "",
      GoogleMabLinkRules: [],
      WebsiteLink: "",
      WebsiteLinkRules: [],
      ContactPersonDetails: "",
      ContactPersonDetailsRules: [],
      ContactPersonEmailId: "",
      ContactPersonEmailIdRules: [],
      ContactPersonMobileNo: "",
      ContactPersonMobileNoRules: [],
      EventImage: {},
      EventImageRules: [],
      Featured: false,
      EventStatus: true,

      EventTypeFlag: false,
      EventType: 1,
      EventTypeRules: [v => !!v || "Program Type is required"],
      EventTypeOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "National Programs" },
        { value: 2, text: "Zone Programs" }
        // { value: 3, text: "Lom Programs" }
      ],
      EventTypeOptionsLoading: false,

      OrganizationType: "",
      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationTypeOptions: [],
      OrganizationTypeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [v => !!v || "Lom is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FromDate: new Date().toISOString().slice(0, 10),
      FromDateRules: [v => !!v || "From Date is required"],
      menu1: false,
      ToDate: new Date().toISOString().slice(0, 10),
      menu2: false,

      StartTime: "",
      menu3: false,
      EndTime: "",
      menu4: false,

      SelectedImage: {
        ImageType: "",
        ImageUrl: null
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        if (this.EventTypeFlag) {
          this.getZoneCodeOptions();
        }
        this.getOrganizationTypeOptions();
      }
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function() {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    EventType: function() {
      console.log("watch EventType");

      this.ZoneCode = "";
      this.LomCodeOptions = [];
      this.LomCode = "";
    },
    OrganizationType: function () {
      console.log("Watch JciYearCode called");
      this.OrganizationTypeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;

      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "national_calendar_events",
        Action: "create"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      this.LomCode = "";
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 0
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.EventImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null
          };
          this.EventImage = {};
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      var validate1 = this.$refs.form1.validate();
      var EventTitle = this.EventTitle;
      var FromDate = this.FromDate;

      if (EventTitle != "" && FromDate != "" && validate1) {
        var EventStatus = this.EventStatus ? 1 : 0;
        var Featured = this.Featured ? 1 : 0;

        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Zone", this.ZoneCode);
        upload.append("Lom", this.LomCode);
        upload.append("EventTitle", this.EventTitle);
        upload.append("EventDescription", this.EventDescription);
        upload.append("EventContent", this.EventContent);
        upload.append("EventLocation", this.EventLocation);
        upload.append("FromDate", this.FromDate);
        upload.append("ToDate", this.ToDate);
        upload.append("StartTime", this.StartTime);
        upload.append("EndTime", this.EndTime);
        upload.append("GoogleMabLink", this.GoogleMabLink);
        upload.append("WebsiteLink", this.WebsiteLink);
        upload.append("ContactPersonDetails", this.ContactPersonDetails);
        upload.append("ContactPersonEmailId", this.ContactPersonEmailId);
        upload.append("ContactPersonMobileNo", this.ContactPersonMobileNo);
        upload.append("Featured", Featured);
        upload.append("EventStatus", EventStatus);
        upload.append("OrganizationTypeCode", this.OrganizationType);

        var file = this.EventImage;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob(blob => {
            upload.append("EventImage", blob, FileName);
            this.submitForm(upload);
          }, FileType);
        } else {
          upload.append("EventImage", null);
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      this.submitFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/calendar/events/create";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.submitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$emit("hideAddRecordPrompt");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function(error) {
          console.log("error=" + error);
          thisIns.submitFlag = true;
        });
    },
    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");

      this.OrganizationTypeOptionsLoading = true;
      this.OrganizationType = "";
      var selectbox1_source = "OrganizationType";
      var selectbox1_destination = "OrganizationTypeOptions";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };

      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#programs-create {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>